html, body, #root {
  margin: 0;
  height: 100%;
}

.ui.container.page {
  padding: 30px 0;
}

.application-menu {
  border-radius: 0 !important;
}

button.ui.right.floated {
  margin-bottom: 14px;
}

.ui.selectable.table tr:hover {
  cursor: pointer;
}
