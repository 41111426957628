* {
  font-family: "Roboto", sans-serif;
}

h1 {
  color: #333;
}

.container-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.container-acess {
  display: flex;
  padding-top: 3rem;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 0 5rem;
  height: 100%;
  position: relative;
  overflow-y: auto;
}

.content-acess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.obrigatory {
  color: #f00;
}

.mt-1 {
  padding-top: 1rem;
}

.content-acess img {
  padding-top: 2rem;
}

.title-acess {
  margin: 8rem 4rem;
}

.title-acess h2 {
  font-size: 30px !important;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-shadow: 0px 4px #00000030;
}

.container-img {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/hero-trial.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

form {
  width: 100%;
  padding: 3rem 0;
}

label {
  font-weight: 600;
  font-size: 14px;
}

.input-item {
  width: 100%;
  padding: 1rem 0;
}

.input-item-half {
  display: flex;
  padding: 1rem 0;
}

.input-item-half div {
  width: 100%;
}

.mr-3 {
  margin-right: 2rem;
}

.input {
  width: 100%;
}

.checkbox-container {
  display: flex;
  flex-direction: row !important;
}

.checkbox {
  margin-right: 1rem;
}

span {
  font-size: 14px;
  font-weight: 500;
}

.button-container {
  width: 100%;
  padding: 3rem 0;
}

.button {
  background-color: #2b5c9e;
  color: #fff;
  font-weight: 600;
}

@media (max-width: 576px) {
  .container-img{
    display: none;
  }

  .input-item-half {
    flex-direction: column;
    padding: 0;
  }

  .mt-half-1 {
    margin: 1rem 0;
  }

  main > div {
    text-align: center;
  }

  .container-acess{
    padding: 0 2rem;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .container-img{
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .input-item-half {
    flex-direction: column;
    padding: 0;
  }

  .mt-half-1 {
    margin: 1rem 0;
  }
}